.app {
    margin: 20px;
}

.interactions, .error {
    text-align: center;
}

.card {
    margin-left: 5px;
    margin-right: 5px;
}

.container-fluid {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.favourite {
    background-color: red;
}